* {
  color: #34393a;
  line-height: 1.5;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Proza Libre', sans-serif;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
  font-weight: 300;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.8;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.m-top {
  margin-top: 4rem;
}

.m-bottom {
  margin-bottom: 4rem;
}

.nav-menu-container {
  margin-top: 8rem;

}

/* Nav */

.nav-menu-items {

}

.nav-menu-list {
  list-style: none;
}

.nav-menu-list a {
  text-decoration: none;
  font-family: 'Manrope', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  border-bottom: 0px solid black;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: black;
}

.nav-menu-list a:visited {
  text-decoration: none;
  color: black;
  border-bottom: 15px solid black;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.nav-menu-list a:hover {
  color: #296e6c;
  border-bottom: 15px solid #296e6c;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.nav-logo-container {
  margin-right: 2rem;
  margin-bottom: 4rem;
}

.nav-logo {
width: auto;
max-height: 10rem;
border: none;
}

.cart-icon-container {
  vertical-align: middle;
  text-align: center;
}

.cart-icon {
  height: 3rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.badge {
  text-align: right;
  padding-right: 1rem;
  vertical-align: middle;
  margin-top: -5.5rem;
  height: 5rem;
  border-radius: 1000px;
  font-weight: bolder;
  font-size: 100%;
  max-width: 10rem;
  margin-left: auto;
  margin-right: auto;
}

.nav-logo-container a:visited {
  border-bottom: 0px solid black;
}

.nav-logo-container a:hover {
  border-bottom: 0px solid black;
}

.nav-logo-container a {
  border-bottom: 0px solid black;
}

/* Cart */

.cart-item-text {
  max-width: 42ch;
}


.cart-mod-icon-container {
  margin-top: -5rem;
  margin-left: 4rem;
  float: right;
}

.cart-mod-button {
  padding: 0.5rem 1rem 0rem 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.cart-mod-icon {
  height: 2rem;
}

.cart-button {
}


button:disabled {
  background-color: rgb(230, 230, 230);
  color: darkgray;
  border-color: white;
  cursor: default;
}

/* Gallery */

.gallery-image {
  display: block;
  border: none;
  margin-bottom: 4rem;
  /* padding: 0.2rem; */
  cursor: pointer;
  -webkit-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  width: 100%;
  height: 40rem;
}

.gallery-image img {
  display: block;
  height: 40rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.gallery-image:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
  -webkit-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

.gallery-image:hover .image-text h2{
  color: white;
}

.gallery-image p {
  font-size: larger;
  font-weight: 300;
}

.image-page-container {
  padding: 0;
  width: 100%;
}

.image-page-container p {
  font-size: 1.7rem;
}

.image-page-image {
  height: auto;
  width: 100%;
  margin: 1rem 0 4rem 0;
  border: solid rgb(238, 238, 231) 4px;
}

.image-medium {
  margin-top: -2.5rem;
  margin-bottom: 5rem;
}

/* Shop */

@media (max-width: 400px) {
  .shop-container {
    max-width: 40rem;
    min-width: 25rem;
  }
  .nav-menu-container li {
    margin: 1rem auto 1rem auto;
  }
  .nav-logo {
    margin-bottom: 4rem;
  }
  .price-button {
    min-width: unset!important;
    max-width: unset!important;
    width: 100%!important;
  }
}

@media (max-width: 955px) {
.image-page-image {
  width: 100%;
}
.cart-icon {
  margin-top: 1rem;
}
.cart-icon-container {
  height: 4rem;
  margin-bottom: 4rem;
}
.cart-mod-icon-container {
  margin-top: 0rem;
  margin-left: 0rem;
  margin-bottom: 4rem;
  float: none;
}
.cart-item-text {
  margin-left: auto;
  margin-right: auto;
}
}

.shop-item {
  -webkit-backface-visibility: hidden;
  margin: 1rem 1rem 4rem 0;
  padding: 0.2rem;
  -webkit-box-shadow: rgb(226, 226, 226) -2px 2px 8px;
          box-shadow: rgb(226, 226, 226) -2px 2px 8px;
}

.shop-item h2 {
  margin-top: 1.5rem;
  font-size: 2.2rem;
}

.shop-item img {
  width: 90%;
  border: solid rgb(238, 238, 231) 2px;
  margin: 0 auto 4rem auto;
}

.print-item {
  margin: 1rem 1rem 4rem 0;
  padding: 0.2rem;
  -webkit-box-shadow: rgb(226, 226, 226) -2px 2px 8px;
          box-shadow: rgb(226, 226, 226) -2px 2px 8px;
}

.print-item h2 {
  margin-top: 1.5rem;
  font-size: 2.2rem;
}

.print-page-image {
  width: 100%;
  border: solid rgb(238, 238, 231) 4px;
  margin: 0 auto 4rem auto;
}


.print-item-prices {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.shop-item-prices {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.price-button {
  border: 1px solid rgb(233, 233, 233);
  margin: 0rem 0.5rem 3rem 0.5rem;
  padding-bottom: 1rem;
  min-width: 15rem;
  max-width: 18rem;
  min-height: 20rem;
}

.price-button-inner {
  margin-left: 1rem;
  padding-top: 1.5rem;
}

.price-button button {
  border-radius: 4px;
  height: 4rem;
  margin-left: -1rem;
}

@-webkit-keyframes add-cart {
  0% {background-color: #296e6c;}
  100% {background-color: initial;}
}

@keyframes add-cart {
  0% {background-color: #296e6c;}
  100% {background-color: initial;}
}

@-webkit-keyframes add-cart2 {
  0% {background-color: #296e6c;}
  100% {background-color: initial;}
}

@keyframes add-cart2 {
  0% {background-color: #296e6c;}
  100% {background-color: initial;}
}

.add-to-cart-button.animate {
  -webkit-animation: add-cart 1s;
          animation: add-cart 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.add-to-cart-button.animate2 {
  -webkit-animation: add-cart2 1s;
          animation: add-cart2 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.button-confirm {
  visibility: hidden;
  position: absolute;
}

add-to-cart-button img {
  visibility: visible!important;
  height: 2.5rem;
  -webkit-filter: invert(50%) sepia(90%) hue-rotate(170deg) saturate(1000%);
          filter: invert(50%) sepia(90%) hue-rotate(170deg) saturate(1000%);
}


.price-button button h2 {
  line-break: strict;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: medium;
  margin: auto;
  text-transform: capitalize;
}

.price-button button:hover {
  background-color: rgb(117, 129, 126);
}

.price-button button:hover h2 {
  color: white;
  margin: auto;
  text-transform: capitalize;
}

.price-button p {
  font-size: 1.6rem;
}

.price-number {
  color: rgb(18, 75, 56);
}

/* Partials and Helpers */

.loading-container {
  margin: 10rem auto 20rem auto;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 1s;
}

.social:hover .social-svg-icon {
  fill:rgb(0, 0, 0)!important;
}

.policy-container {
  margin-top: 8rem;
}
